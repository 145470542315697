import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDTaSrWkUJMjOlYUaGToAnK072tQfkp_Wg',
  authDomain: 'rctalert-api.firebaseapp.com', //'evidencehunt.com',
  projectId: 'rctalert-api',
  storageBucket: 'rctalert-api.appspot.com',
  messagingSenderId: '50342661741',
  appId: '1:50342661741:web:b0351fda2a26f2cd71f964',
};

const app = initializeApp(firebaseConfig);

// eslint-disable-next-line import/prefer-default-export
export const auth = getAuth(app);

// Not a good idea to have multiple apps for auth.
// Auth is aLso used by getAuthenticatedHeaders in apps/web/src/lib/authentication.js
// const rctalertApp = initializeApp(
//   {
//     ...firebaseConfig,
//     authDomain: 'rctalert-api.firebaseapp.com',
//   },
//   'rctalert'
// );

// // eslint-disable-next-line import/prefer-default-export
// export const rctalertAuth = getAuth(rctalertApp);
