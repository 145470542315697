<template>
  <v-btn
    color="primary"
    variant="text"
    class="btn-class"
    :ripple="false"
    append-icon="mdi-chevron-down">
    {{ label }}

    <v-menu activator="parent" open-on-hover>
      <v-list>
        <v-list-item
          v-for="(item, index) in children"
          :key="index"
          :value="item?.to || item?.href || index"
          :component="item?.to ? 'router-link' : 'a'"
          :to="item?.to"
          :href="item?.href"
          :target="item?.href ? '_blank' : undefined">
          <v-list-item-title>{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>
<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  children: {
    type: Array[Object],
    default: [],
  },
  label: {
    type: String,
    default: '',
  },
});

const { children, label } = toRefs(props);
</script>
<style lang="scss" scoped>
.btn-class {
  font-family: 'Red Hat Display', 'Nunito', 'Charter', Arial, sans-serif;
  font-weight: 500;
  color: rgb(var(--v-theme-text)) !important;
  height: 54px;
  border-radius: 0px;
  &:hover > span[class*='v-btn__content'] {
    text-decoration: underline;
  }
}
.btn-class.v-btn--variant-plain {
  opacity: 1;
}
</style>
