import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  confirmPasswordReset,
  sendEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  OAuthProvider,
} from 'firebase/auth';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import router from '../../router';
import { config } from './config';
import { createPaddleCustomer } from './paddleHelpers';
import { auth } from '@/lib/firebase';
import { newAbortSignal } from '@/lib/utils';
import { getAuthenticatedHeaders } from '@/lib/authentication';

const { apiBaseUrl, graphqlUrl } = config;

export const login = async ({ dispatch, commit }, payload) => {
  if (process.env.VUE_APP_MODE === 'sandbox') {
    const allowed = await fetchSandboxAccess(payload.email);
    if (!allowed) {
      window.alert('Email is not allowed.');
      return false;
    }
  }

  try {
    const userCredential = await signInWithEmailAndPassword(auth, payload.email, payload.password);

    const { user } = userCredential;
    if (user) {
      await upsertUser();
    }

    if (process.env.VUE_APP_MODE !== 'sandbox' && !user.emailVerified) {
      commit('SET_STATE_PROPERTY', { property: 'isVerificationFromLogin', value: true });
      localStorage.setItem('verificationEmail', payload.email);
      router.push('/verification').catch((err) => {
        Sentry.captureException(err);
        // console.log(err)
      });
      commit('SET_STATE_PROPERTY', { property: 'userForVerificationLink', value: user });
      await signOut(auth);
      return false;
    } else {
      if (user?.uid) {
        const { uid, emailVerified } = user;
        // TODO: removeItem userId and userEmail are items that are no longer used
        localStorage.removeItem('userId');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('verificationEmail');
        const userProfile = await dispatch('fetchUserProfile');

        const localCountry = localStorage.getItem('selectedLocale') === 'nl' ? 'nl' : 'en';

        if (userProfile && userProfile.country && userProfile.country !== localCountry) {
          localStorage.setItem('selectedLocale', userProfile.country);
        }

        if (
          process.env.VUE_APP_MODE !== 'sandbox' &&
          emailVerified &&
          userProfile &&
          !userProfile.isUserVerified
        ) {
          dispatch('updateIsUserVerified', {
            uid,
            isUserVerified: true,
          });
        }

        dispatch('trackEvent', { event: 'UserSignedIn', values: { provider: 'email' } });

        const authRedirectedUrl = localStorage.getItem('authRedirectedUrl');

        if (authRedirectedUrl) {
          router.push(authRedirectedUrl).catch((err) => {
            Sentry.captureException(err);
          });
        } else {
          router.push('/').catch((err) => {
            Sentry.captureException(err);
          });
        }
      }
    }
    return !!userCredential;
  } catch (error) {
    return false;
  }
};

export const microsoftAuth = async ({ dispatch }, payload) => {
  const { optIn = false, redirect = true } = payload;
  const provider = new OAuthProvider('microsoft.com');

  return await signInWithPopup(auth, provider)
    .then(async (userCredential) => {
      const user = userCredential.user;
      const { email, uid } = user;
      if (user) {
        await upsertUser();
      }

      const userInfo = getAdditionalUserInfo(userCredential);

      if (userInfo?.isNewUser) {
        // New user => register
        await mixpanelAddUser(uid, email);

        const country = localStorage.getItem('selectedLocale') === 'nl' ? 'nl' : 'en';
        const { givenName, surname } = userInfo.profile;

        const userDetails = {
          uid,
          email,
          country,
          isUserVerified: true,
          firstName: givenName || '',
          lastName: surname || '',
        };

        // Set userDetails in localStorage, which is used in userTracking.js
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        dispatch('trackEvent', { event: 'NewUserRegistration', values: { provider: 'microsoft' } });

        await createUserProfile(uid, {
          email,
          country,
          isUserVerified: true,
          optIn,
          firstName: givenName,
          lastName: surname,
        });

        dispatch('updateIsUserVerified', { uid, isUserVerified: true });

        try {
          await createPaddleCustomer({}, { email, uid });
        } catch (err) {
          Sentry.captureException(err);
        }
        await dispatch('fetchUserProfile');
        router.push('/').catch((err) => Sentry.captureException(err));
      } else {
        // Existing user => login
        dispatch('socialLogin', { provider: 'microsoft' });
      }
    })
    .catch(async (error) => {
      return await dispatch('sociaError', { error, provider: 'microsoft', redirect });
    });
};

export const googleAuth = async ({ dispatch }, payload) => {
  const { optIn = false } = payload;
  const provider = new GoogleAuthProvider();

  return await signInWithPopup(auth, provider)
    .then(async (userCredential) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(userCredential);
      // const token = credential.accessToken;
      // The signed-in user info.
      const user = userCredential.user;
      const { email, uid } = user;
      if (user) {
        await upsertUser();
      }

      const userInfo = getAdditionalUserInfo(userCredential);

      if (userInfo?.isNewUser) {
        // New user => register
        await mixpanelAddUser(uid, email);

        const country = localStorage.getItem('selectedLocale') === 'nl' ? 'nl' : 'en';
        const { given_name, family_name } = userInfo.profile;

        const userDetails = {
          uid,
          email,
          country: payload?.country ? payload.country : 'en',
          isUserVerified: true,
          firstName: given_name || '',
          lastName: family_name || '',
        };

        // Set userDetails in localStorage, which is used in userTracking.js
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        dispatch('trackEvent', { event: 'NewUserRegistration', values: { provider: 'google' } });

        await createUserProfile(uid, {
          email,
          country,
          isUserVerified: true,
          optIn,
          firstName: given_name,
          lastName: family_name,
        });

        dispatch('updateIsUserVerified', { uid, isUserVerified: true });

        try {
          await createPaddleCustomer({}, { email, uid });
        } catch (err) {
          Sentry.captureException(err);
          // console.log(err);
        }
        await dispatch('fetchUserProfile');
        router.push('/').catch((err) => Sentry.captureException(err));
      } else {
        // Existing user => login
        dispatch('socialLogin', { provider: 'google' });
      }
    })
    .catch(async (error) => {
      return await dispatch('sociaError', { error });
    });
};

export const socialLogin = async ({ dispatch }, payload) => {
  const { provider } = payload;

  // TODO: removeItem userId and userEmail are items that are no longer used
  localStorage.removeItem('userId');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('verificationEmail');
  const userProfile = await dispatch('fetchUserProfile');

  const localCountry = localStorage.getItem('selectedLocale') === 'nl' ? 'nl' : 'en';

  if (userProfile && userProfile.country && userProfile.country !== localCountry) {
    localStorage.setItem('selectedLocale', userProfile.country);
  }
  // Wait for the user profile to be fetched before tracking the event
  dispatch('trackEvent', { event: 'UserSignedIn', values: { provider } });

  const authRedirectedUrl = localStorage.getItem('authRedirectedUrl');

  if (authRedirectedUrl) {
    router.push(authRedirectedUrl).catch((err) => {
      Sentry.captureException(err);
    });
  } else {
    router.push('/').catch((err) => {
      Sentry.captureException(err);
    });
  }
};

// eslint-disable-next-line no-unused-vars
export const sociaError = ({ dispatch }, payload) => {
  const { error } = payload;
  if (error.code === 'auth/popup-closed-by-user') return false;
  if (error.code === 'auth/popup-blocked') {
    window.alert('Your browser blocked the signup popup. Please enable popups and try again.');
    return false;
  }

  Sentry.captureException(error);

  if (error.code === 'auth/account-exists-with-different-credential') {
    return 'auth/account-exists-with-different-credential';
  } else {
    console.error(error.message);
  }
  // Handle Errors here.

  // const errorCode = error.code;
  // const errorMessage = error.message;
  // // The email of the user's account used.
  // const email = error.customData.email;
  // // The AuthCredential type that was used.
  // const credential = GoogleAuthProvider.credentialFromError(error);

  // ...
  return false;
};

export const register = async ({ dispatch, commit }, payload) => {
  if (process.env.VUE_APP_MODE === 'sandbox') {
    const allowed = await fetchSandboxAccess(payload.email);
    if (!allowed) {
      window.alert('Email is not allowed.');
      return;
    }
  }

  await createUserWithEmailAndPassword(auth, payload.email, payload.password)
    .then(async (userCredential) => {
      const { user } = userCredential;
      if (user) {
        await upsertUser();
      }
      if (!user?.emailVerified) {
        await mixpanelAddUser(user.uid, payload.email);

        payload.isUserVerified = false;
        // Set the user's country if other language was selected in toggle
        if (!payload.country && localStorage.getItem('selectedLocale')) {
          payload.country = localStorage.getItem('selectedLocale');
        }

        const userDetails = {
          uid: user.uid,
          email: payload.email,
          country: payload?.country ? payload.country : 'en',
          isUserVerified: false,
        };

        // Set userDetails in localStorage, which is used in userTracking.js
        localStorage.setItem('userDetails', JSON.stringify(userDetails));

        dispatch('trackEvent', { event: 'NewUserRegistration', values: { provider: 'email' } });

        await createUserProfile(user.uid, payload);
        try {
          await createPaddleCustomer({}, { email: payload.email, uid: user.uid });
        } catch (err) {
          Sentry.captureException(err);
          // console.log(err);
        }

        // Don't need to verify email in sandbox mode
        if (process.env.VUE_APP_MODE !== 'sandbox') {
          // Send verification email and logout user
          localStorage.setItem('verificationEmail', payload.email);
          commit('SET_STATE_PROPERTY', { property: 'isVerificationFromLogin', value: false });
          router.push('/verification').catch((err) => Sentry.captureException(err));
          commit('SET_STATE_PROPERTY', { property: 'userForVerificationLink', value: user });
          sendEmailVerificationRequest(user);
          commit('SET_STATE_PROPERTY', { property: 'userProfile', value: {} });
          localStorage.removeItem('userProfile');
          await signOut(auth);
          return;
        } else {
          // Redirect to main page after registration
          await dispatch('fetchUserProfile');
          router.push('/').catch((err) => Sentry.captureException(err));
        }
      } else {
        // This happens when the user is already verified, which should not happen
        signOut(auth).then(() => {
          router.push('/verification').catch((err) => Sentry.captureException(err));
        });
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
      window.alert(error.message);
    });
};

export const upsertUser = async () => {
  axios.get(`${apiBaseUrl}/upsert-user`, await getAuthenticatedHeaders()).catch((err) => {
    Sentry.captureException(err);
    // console.log(err);
  });
};

const mixpanelAddUser = async (uid, email) => {
  axios
    .post(
      `${apiBaseUrl}/mixpanel-add-user`,
      { client_id: uid, known_email: email },
      await getAuthenticatedHeaders({
        headers: {
          'Content-Type': 'application/json',
        },
      })
    )
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const logout = async ({ commit, dispatch }) => {
  await signOut(auth);

  const selectedLocale = localStorage.getItem('selectedLocale');

  localStorage.clear();
  if (selectedLocale) localStorage.setItem('selectedLocale', selectedLocale);

  commit('RESET');

  dispatch('trackEvent', {
    event: 'UserSignedOut',
  });
  router.push('/login');
};

// eslint-disable-next-line no-unused-vars
export const checkRegistered = async ({ dispatch }, email) => {
  // returns a list of sign in methods for used emails
  const response = await fetchSignInMethodsForEmail(auth, email);
  return !!response.length;
};

export const resetPasswordRequest = async ({ dispatch }, email) => {
  const isRegistered = await dispatch('checkRegistered', email);
  if (!isRegistered) throw new Error('Email not found');
  await sendPasswordResetEmail(auth, email);
};

export const resetPassword = async ({ dispatch }, payload) => {
  await confirmPasswordReset(auth, payload.code, payload.password).then(() => {
    dispatch('trackEvent', {
      event: 'UserPasswordResetSuccess',
    });
  });
};

export const sendEmailVerificationRequest = async (user) => {
  try {
    await sendEmailVerification(user);
    // console.log('Verification email sent.');
  } catch (error) {
    console.error('Error sending verification email:', error);
  }
};

export const resendEmailVerificationRequest = async ({ state }) => {
  const user = state.userForVerificationLink;
  try {
    await sendEmailVerification(user);
    // console.log('Verification email sent.');
  } catch (error) {
    console.error('Error sending verification email:', error);
  }
};

export const updateUserProfile = async ({ dispatch }, payload) => {
  const query = `{
        updateUserDetails(userProfile: {
            uid: "${payload.uid}"
            firstName: "${payload.firstName ? payload.firstName : ''}"
            lastName: "${payload.lastName ? payload.lastName : ''}"
            occupation: "${payload.occupation ? payload.occupation : ''}"
            country: "${payload.country ? payload.country : 'en'}"
            userLanguage: "${payload.userLanguage ? payload.userLanguage : ''}"
            institution: "${payload.institution ? payload.institution : ''}"
        })
    }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then(() => {
      dispatch('fetchUserProfile');
    })
    .catch((err) => {
      Sentry.captureException(err);
      // console.log(err);
    });
};

export const createUserProfile = async (userUid, payload) => {
  const query = `{
        createUser(userProfileCreate: {
            uid: "${userUid}"
            email: "${payload?.email ? payload.email : ''}"
            country: "${payload?.country ? payload.country : 'en'}"
            isUserVerified: ${payload?.isUserVerified ? 'true' : 'false'}
            optIn: ${payload?.updates ? 'true' : 'false'}
            firstName: "${payload?.firstName ? payload.firstName : ''}"
            lastName: "${payload?.lastName ? payload.lastName : ''}"
        })
    }`;

  try {
    await axios.post(graphqlUrl, { query }, await getAuthenticatedHeaders());
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const updateUserLanguage = async ({ dispatch }, payload) => {
  const query = `{
    updateUserLanguage(userProfile: {
            uid: "${payload.uid}"
            country: "${payload.country ? payload.country : 'en'}"
        })
    }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then(() => {
      dispatch('fetchUserProfile');
    })
    .catch((err) => {
      Sentry.captureException(err);
      // console.log(err);
    });
};

export const updateIsUserVerified = async ({ commit, dispatch }, { uid, isUserVerified }) => {
  const query = `{
    updateIsUserVerified(
          uid: "${uid}"
          isUserVerified: ${isUserVerified}
        )
    }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then(() => {
      commit('SET_STATE_PROPERTY', { property: 'isUserVerified', value: isUserVerified });
      dispatch('fetchUserProfile');
    })
    .catch((err) => {
      Sentry.captureException(err);
      // console.log(err);
    });
};

export const fetchUserProfile = async ({ commit, dispatch }) => {
  commit('SET_STATE_PROPERTY', { property: 'isUserLoading', value: true });
  if (auth.currentUser && auth.currentUser.uid) {
    try {
      const res = await axios.get(
        `${apiBaseUrl}/users/me`,
        await getAuthenticatedHeaders({ signal: newAbortSignal(30000) })
      );

      const userDetails = res.data.data;
      // console.log(userDetails);
      localStorage.setItem('userDetails', JSON.stringify(userDetails));

      commit('SET_STATE_PROPERTY', {
        property: 'currentWeekProUsage',
        value: userDetails.currentWeekProUsage || 0,
      });

      if (userDetails.pendingInvites?.length) {
        commit('SET_STATE_PROPERTY', {
          property: 'invites',
          value: [...userDetails.pendingInvites],
        });
      }

      if (userDetails.organizationRoles?.length) {
        const organizations = [];
        const seenIds = new Set();
        /**If the user has two roles in org, it is good to filter organizations */
        for (const { organization } of userDetails.organizationRoles) {
          if (organization && !seenIds.has(organization.id)) {
            organizations.push(organization);
            seenIds.add(organization.id);
          }
        }

        commit('SET_STATE_PROPERTY', { property: 'organizations', value: organizations });

        const selectedUser = localStorage.getItem('selectedUser');

        if (organizations.length) {
          if (!selectedUser) {
            let organizationId = localStorage.getItem('selectedOrganizationId');
            let organization;
            if (organizationId) {
              const localOrganization = organizations.find((org) => org.id === organizationId);
              if (localOrganization) {
                organization = localOrganization;
              } else {
                localStorage.removeItem('selectedOrganizationId');
                organization = organizations[organizations.length - 1];
              }
            } else {
              organization = organizations[organizations.length - 1];
            }

            dispatch('selectOrganizationId', { organizationId: organization.id });
          } else {
            commit('SET_STATE_PROPERTY', { property: 'organization', value: null });
            commit('SET_STATE_PROPERTY', { property: 'organizationSources', value: [] });
          }
        } else {
          commit('SET_STATE_PROPERTY', { property: 'organization', value: null });
          commit('SET_STATE_PROPERTY', { property: 'organizationSources', value: [] });
          commit('SET_STATE_PROPERTY', { property: 'organizations', value: [] });
        }
      }

      if (
        userDetails.subscriptionStatus === 'trialing' ||
        userDetails.subscriptionStatus === 'active'
      ) {
        localStorage.setItem('paddlePaymentInProgress', false);
      }

      if (
        userDetails.subscriptionStatus === 'canceled' ||
        userDetails.scheduled_change?.action !== 'null'
      ) {
        commit('SET_STATE_PROPERTY', {
          property: 'isPaddlePaymenCancelationInProgress',
          value: false,
        });
      }

      // Avoid undefined country
      if (userDetails.country && !['nl', 'en'].includes(userDetails.country)) {
        userDetails.country = 'en';
      }

      commit('SET_STATE_PROPERTY', { property: 'userProfile', value: userDetails });

      if (userDetails.country) {
        commit('SET_STATE_PROPERTY', { property: 'locale', value: userDetails.country });
      }

      if (process.env.VUE_APP_MODE !== 'sandbox') {
        if (userDetails.paddleCustomerId === null && userDetails.email && userDetails.uid) {
          // console.log('Creating Paddle Customer...');
          const paddleCustomerId = await dispatch('createPaddleCustomer', {
            email: userDetails.email,
            uid: userDetails.uid,
          });
          // console.log(paddleCustomerId);
          if (paddleCustomerId && typeof paddleCustomerId === 'string') {
            userDetails.paddleCustomerId = paddleCustomerId;
          }
        }
      }
      // console.log('userDetails', userDetails);
      return userDetails;
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      commit('SET_STATE_PROPERTY', { property: 'isUserLoading', value: false });
    }
    return null;
  } else {
    commit('SET_STATE_PROPERTY', { property: 'userProfile', value: {} });
    return null;
  }
};

export const selectUser = async ({ commit }) => {
  // Clear organization
  commit('SET_STATE_PROPERTY', { property: 'organization', value: null });
  commit('SET_STATE_PROPERTY', { property: 'organizationSources', value: [] });
  localStorage.removeItem('selectedOrganizationId');
  localStorage.setItem('selectedUser', true);
};

export const selectOrganizationId = async ({ commit, state }, { organizationId }) => {
  const organizations = state.organizations || [];

  const organization = organizations.find((org) => org.id === organizationId);
  if (!organization) {
    Sentry.captureException('Could not select organization with id: ' + organizationId);
    console.error('Could not select organization with id: ' + organizationId);
    return;
  }

  localStorage.setItem('selectedOrganizationId', organization.id);
  localStorage.removeItem('selectedUser');

  commit('SET_STATE_PROPERTY', {
    property: 'organization',
    value: organization,
  });

  const organizationSources = [];

  if (organization.sources && organization.sources.length) {
    const sources = organization.sources.map(({ name, key, language }) => {
      return {
        text: name,
        value: key,
        language,
      };
    });
    organizationSources.push(...sources);
  }

  commit('SET_STATE_PROPERTY', {
    property: 'organizationSources',
    value: organizationSources,
  });
  // console.log(organizationSources);
  // console.log(organization);
};

export const unsubscribeMarketing = async (_, email) => {
  axios.get(`${apiBaseUrl}/unsubscribe?email=${email}`).catch((err) => {
    Sentry.captureException(err);
    // console.log(err);
  });

  router.push('/unsubscribed');
};

export const fetchSandboxAccess = async (email) => {
  try {
    const res = await axios.post(`${apiBaseUrl}/sandbox/access`, { email });
    return res.data?.data?.allowed;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};
