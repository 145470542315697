// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';

const lightTheme = {
  dark: false,
  colors: {
    primary: '#0362FF',
    'primary-lighten-1': '#68A1FF',
    'primary-lighten-2': '#327FFF',
    secondary: '#DDDFEA',
    'secondary-darken-1': '#6A6F81B2',
    'secondary-darken-2': '#131A29',
    'secondary-darken-3': '#9E9EA1',
    'secondary-darken-4': '#131313',
    bg: '#FFFFFF',
    'bg-darken-1': '#FAFBFF',
    text: '#0F1C37',
    'on-bg': '#0F1C37',
    'text-lighten-1': '#6A6F81',
    'text-lighten-2': '#384060',
    error: '#E62A2A',
  },
};

const vuetify = createVuetify({
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme,
    },
    typography: {
      fontFamily: ['Red Hat Display', 'Nunito', 'Arial', 'sans-serif'].join(','),
    },
  },
  display: {
    mobileBreakpoint: 'sm',
    thresholds: {
      xs: 0,
      // sm: 600,
      sm: 768,
      // md: 960,
      md: 1050,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default vuetify;
