/* eslint-disable import/prefer-default-export */
import TwitterIcon from '@/assets/icons/social-media/twitter.svg';
import LinkedinIcon from '@/assets/icons/social-media/linkedin.svg';
import RedditIcon from '@/assets/icons/social-media/reddit.svg';

export const socialLinks = [
  {
    id: 'x',
    name: 'X',
    icon: TwitterIcon,
    route: 'https://twitter.com/Evidence_Hunt',
  },
  {
    id: 'linkedin',
    name: 'LinkedIn',
    icon: LinkedinIcon,
    route: 'https://www.linkedin.com/company/evidencehunt/?viewAsMember=true',
  },
  {
    id: 'reddit',
    name: 'Reddit',
    icon: RedditIcon,
    route: 'https://www.reddit.com/r/EvidenceHuntCommunity/',
  },
];
