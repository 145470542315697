export function newAbortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

export function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms));
}

export function organizationHeader(organization) {
  if (organization && organization.id) {
    return { headers: { 'organization-id': organization.id } };
  } else {
    return {};
  }
}

export function formatDate(date, locale) {
  return date
    ? new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(date))
    : '-';
}

export function createDebounce(delayMs = 300) {
  let timeout = null;
  return function (fnc) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs);
  };
}

export const defaultPageSizeOptions = [
  { value: 5, title: '5' },
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' },
];
