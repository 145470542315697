<template>
  <v-container
    class="page-section"
    :class="{
      'page-section--dark': dark,
      'page-section--mobile': mobile,
    }"
    fluid>
    <v-container
      class="page-section__content"
      :class="{
        [`w-90 max-size-${size}`]: !mobile,
      }">
      <slot />
    </v-container>
  </v-container>
</template>

<script setup>
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();

defineProps({
  dark: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'l',
  },
});
</script>

<style lang="scss" scoped>
.page-section {
  padding: rem(60px) 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: rgb(var(--v-theme-bg));
  &--dark {
    background-color: rgb(var(--v-theme-bg-darken-1));
  }
  &__content {
    padding-block: 0;
  }
  &--mobile {
    padding: rem(64px) 0;
  }
  &--mobile & {
    &__content {
      padding: 0;
    }
  }
}
</style>
