export const sourcesFormatted = [
  {
    text: 'GGZ Standaarden',
    value: 'ggz',
    language: 'nl',
  },
  // {
  //   text: 'GGZ-NHN Demo',
  //   value: 'ggz-nhn-demo',
  //   language: 'nl',
  // },
  {
    text: 'Farmacotherapeutisch Kompas',
    value: 'fk',
    language: 'nl',
  },
  {
    text: 'NICE Guidelines',
    value: 'nice',
    language: 'en',
  },
];

export function defaultSources() {
  return sourcesFormatted.map((source) => source.value);
}

export const sourcesCustomFormatted = [
  // {
  //   text: 'Boom Leerboek Psychiatrie',
  //   value: 'boom',
  //   language: 'nl',
  // },
];
