<template>
  <div
    class="section-columns"
    :class="{
      'section-columns--mobile': mobile,
    }">
    <div
      class="section-columns__col section-columns__col--left"
      :class="{
        'section-columns__col--short': (growRight && !growLeft) || shortLeft,
      }">
      <slot name="left" />
    </div>
    <div
      class="section-columns__col section-columns__col--right"
      :class="{
        'section-columns__col--short': (growLeft && !growRight) || shortRight,
      }">
      <slot name="right" />
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();

defineProps({
  growLeft: {
    type: Boolean,
    default: false,
  },
  growRight: {
    type: Boolean,
    default: false,
  },
  shortLeft: {
    type: Boolean,
    default: false,
  },
  shortRight: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.section-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(16px);
  &__col {
    &--short {
      max-width: rem(380px);
      margin-top: rem(12px);
    }
  }
  &--mobile {
    grid-template-columns: 1fr;
  }
  &--mobile & {
    &__col {
      &--short {
        max-width: unset;
        margin-top: 0;
      }
    }
  }
}
</style>
