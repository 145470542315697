<template>
  <div
    class="content"
    :class="{
      'content--mobile': mobile,
    }">
    <component :is="tag" v-if="title" class="content__title">
      {{ title }}
    </component>
    <p v-if="text || hasSlot('text')" class="content__text">
      <slot name="text" />
      <template v-if="!hasSlot('text')">
        {{ text }}
      </template>
    </p>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { useDisplay } from 'vuetify';
import { hasSlot } from '@/composables';

const { mobile } = useDisplay();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  h: {
    type: [Number, String],
    default: 3,
  },
});

const { h } = toRefs(props);

const tag = computed(() => `h${h.value}`);
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: rem(16px);
  flex-grow: 0;
  flex-shrink: 0;
  white-space: break-spaces;
  &--mobile {
    gap: rem(12px);
  }
  &__title {
    @include text20;
  }
  &__text {
    @include text16($font-weight: 400);
  }
  &--mobile & {
    &__title {
      @include text18;
    }
    &__text {
      @include text14($font-weight: 400);
    }
  }
}
</style>
