const converter = require('json-2-csv');

export const sourceNameConfig = {
  nice: 'NICE Guidelines',
  ggz: 'GGZ Standaarden',
  // 'ggz-nhn-demo': 'GGZ-NHN Demo',
  fk: 'Farmacotherapeutisch Kompas',
  pubmed: 'PubMed',
  // boom: 'Boom',
};

export const getSourceUrl = (reference) => {
  const { type } = reference;
  if (type === 'pubmed') return `https://pubmed.ncbi.nlm.nih.gov/${reference.pmid}`;
  if (type === 'pdf') {
    return (!reference.url === 'NA' && reference.url) || reference.path;
  }
  return reference.url;
};

export const getSourceNameValue = (reference) => {
  const { type } = reference;
  return type === 'pdf' ? reference.source : type;
};

export const getSourceName = (reference) => {
  const sourceValue = getSourceNameValue(reference);
  return sourceNameConfig[sourceValue] || sourceValue;
};

export const getReferenceContentLine = (reference, forcePubmed = false) => {
  const isPubmed = reference.type === 'pubmed' || forcePubmed;
  const resultList = [];
  if (isPubmed) {
    const { journal } = reference;
    const author = reference.first_author;
    const year = reference.date ? new Date(reference.date).getFullYear() : null;
    const details = [journal, author, year, `PMID: ${reference.pmid}`];
    resultList.push(reference.title, ...details);
  } else if (reference.type === 'pdf') {
    resultList.push(getSourceName(reference), `Page: ${reference.page}`);
  }
  return resultList.filter((el) => !!el).join(' | ');
};

export const getMessageUsedIds = (payload) => {
  const message = payload.replace(/\[\\\[id\\\]\]\((\d+)\)/g, (_, match) => `[${match}]`); // TODO remove when chat answer is fixed

  const regex = /\[(.*?)\]/g;
  const matches = [];
  let match;

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(message)) !== null) {
    const links = match[1].split(',').map((link) => link.trim());
    matches.push(...links);
  }
  const result = [...new Set(matches)];
  return result;
};

export const downloadDocs = async (docs, filename) => {
  const getLongest = (arr) => {
    const longest = arr.reduce((a, b) => (a.length > b.length ? a : b));
    return longest;
  };

  const export_docs = docs.map((doc) => ({
    ...doc,
    ml_p: getLongest(doc.ml_p.split(' ; ')),
    ml_i: getLongest(doc.ml_i.split(' ; ')),
    ml_c: getLongest(doc.ml_c.split(' ; ')),
    ml_o: getLongest(doc.ml_o.split(' ; ')),
    ml_b: getLongest(doc.ml_b.split(' ; ')),
  }));
  const keys = [
    'pmid',
    'title',
    'first_author',
    'publication_date',
    'journal',
    'ml_type',
    'ml_n',
    'ml_p',
    'ml_i',
    'ml_c',
    'ml_o',
    'ml_b',
  ];
  const opts = { keys };
  const csv = await converter.json2csv(export_docs, opts);

  const blob = new Blob([csv], {
    type: 'csv',
  });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const exportRis = async (docs, fileName) => {
  let risData = docs
    .map((doc) => {
      const date = new Date(doc.publication_date);

      let authorsRis = '';
      if (doc.authors && doc.authors.length > 0) {
        authorsRis = doc.authors
          .map((author) => `AU  - ${author.firstname} ${author.lastname}`)
          .join('\n');
      }

      const risFields = [
        `TY  - JOUR`,
        authorsRis,
        `TI  - ${doc.title}`,
        `AB  - ${doc.abstract}`,
        `DA  - ${doc.publication_date}`,
        `PY  - ${date.getFullYear()}`,
        `JO  - ${doc.journal}`,
        `DO  - ${doc.doi}`,
        `UR  - https://pubmed.ncbi.nlm.nih.gov/${doc.pmid}/`,
        `ER  - `,
      ]
        .filter(Boolean)
        .join('\n'); // Filter out any empty strings before joining

      return risFields;
    })
    .join('\n');

  const blob = new Blob([risData], { type: 'application/x-research-info-systems' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(link.href);
};
