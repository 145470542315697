<template>
  <v-btn class="social-media-link" icon flat @click="openLink" :title="name">
    <slot>
      <img class="social-media-link__icon" :src="icon" :alt="name" />
    </slot>
  </v-btn>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '#',
  },
  name: {
    type: String,
    default: 'icon',
  },
});
const { icon, link, name } = toRefs(props);

const openLink = () => {
  window.open(link.value, '_blank');
};
</script>

<style lang="scss" scoped>
.social-media-link {
  width: rem(24px);
  height: rem(24px);
  padding: 0 rem(2px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--v-theme-secondary-darken-1), 0.7);
  &__icon {
    width: 100%;
    height: 100%;
  }
}
</style>
