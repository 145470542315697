<template>
  <v-text-field
    validate-on="blur"
    ref="inputEl"
    class="base-input text-secondary"
    :class="{
      'base-input--labeled': !!label,
      'base-input--dark': dark,
      'base-input--mobile': mobile,
    }"
    bg-color="bg-bg"
    variant="outlined"
    flat
    rounded="lg"
    density="compact"
    :label="label"
    hide-details="auto">
    <template v-if="hasSlot('label')" #label>
      <slot name="label" />
    </template>
    <template v-if="hasSlot('prepend-inner')" #prepend-inner>
      <slot name="prepend-inner" />
    </template>
    <template v-if="hasSlot('append-inner')" #append-inner>
      <slot name="append-inner" />
    </template>
    <template v-if="hasSlot('prepend')" #prepend>
      <slot name="prepend" />
    </template>
    <template v-if="hasSlot('append')" #append>
      <slot name="append" />
    </template>
  </v-text-field>
</template>

<script setup>
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
import { hasSlot } from '@/composables';

const inputEl = ref(null);

defineExpose({
  inputEl,
});

const { mobile } = useDisplay();

defineProps({
  label: {
    type: String,
    default: undefined,
  },
  dark: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.base-input {
  position: relative;
  &--labeled {
    padding-top: rem(22px);
  }
  &--dark {
    :deep() {
      .v-field {
        background-color: $color-bg-darken-1 !important;
      }
    }
  }
  :deep() {
    .v-field {
      height: rem(44px);
      display: flex;
      flex-direction: column;
      background-color: $color-bg;
      // hide arrows:
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
      }
      input[type='number'] {
        -moz-appearance: textfield; /* Firefox */
      }
      .v-field__outline {
        --v-field-border-opacity: 0.7;
      }
      &:hover {
        .v-field__outline {
          --v-field-border-opacity: 1;
        }
      }
      input {
        &::placeholder {
          color: $color-text-lighten-1;
          opacity: 0.8;
        }
      }
    }
    .v-field__field {
      height: 100%;
      .v-label {
        color: $color-text;
        @include text16;
      }
    }
    .v-field__input {
      min-height: unset;
      height: 100%;
      @include text16;
      color: $color-text;
      &::placeholder {
        color: $color-text !important;
      }
    }
    .v-input__details {
      padding-left: rem(12px);
    }
    .v-field--focused.v-field--variant-outlined .v-field__outline__notch {
      &::before {
        opacity: 1 !important;
      }
    }
    .v-field--active.v-field--variant-outlined .v-field__outline__notch {
      &::before {
        opacity: var(--v-field-border-opacity) !important;
      }
    }
    .v-field--variant-outlined .v-label.v-field-label--floating {
      transform: translateY(-125%) translateX(calc((var(--v-input-control-height) / 2 + 2px) * -1));
      opacity: 1;
      color: $color-text;
      margin: 0;
    }
    .v-field--error:not(.v-field--disabled) .v-label.v-field-label {
      color: $color-error;
    }
  }
  &--mobile {
    :deep() {
      .v-field__input {
        @include text14;
      }
      .v-field__field {
        .v-label {
          @include text14;
        }
      }
    }
  }
}
</style>
