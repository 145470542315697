import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { config, page_size, fuzziness } from './config';
import { changeQuotes } from '@/utils';
import { getAuthenticatedHeaders } from '@/lib/authentication';

const { apiBaseUrl, graphqlUrl } = config;

function escapeCharacter(str) {
  if (!str) {
    return str;
  }
  return changeQuotes(str);
}

export const loadNextPage = async ({ dispatch, state }, { page }) => {
  switch (state.s_search_type) {
    case 'pico':
      dispatch('searchPico', {
        pico: state.current_pico,
        page,
        sort_by: state.s_sort_by,
        sort_order: state.s_sort_order,
        current_week: state.s_search_current_week,
        article_type_list: state.article_type_list,
      });
      break;
    case 'category':
      dispatch('getTopics', {
        qids: state.current_qids,
        page,
        sort_by: state.s_sort_by,
        sort_order: state.s_sort_order,
        current_week: state.s_search_current_week,
        article_type_list: state.article_type_list,
      });
      break;
    case 'pubmed':
      dispatch('searchPubmed', {
        pubmed_query: state.current_pubmed,
        page,
        article_type_list: state.article_type_list,
        current_week: state.s_search_current_week,
        sort_by: state.s_sort_by,
        sort_order: state.s_sort_order,
      });
      break;
    default:
      break;
  }
};

export const searchPico = async (
  { commit, state },
  { init, pico, page, sort_by, sort_order, current_week, article_type_list }
) => {
  commit('SET_STATE_PROPERTY', { property: 'loading', value: true });
  commit('SET_STATE_PROPERTY', { property: 'showRecent', value: false });

  state.article_type_list = article_type_list;
  state.current_pico = pico;
  state.s_search_type = 'pico';

  const query = [];
  query.push({
    queryString: escapeCharacter(pico.population_must),
    fieldName: 'ml_p',
    boolType: 'must',
    operator: 'AND',
  });
  query.push({
    queryString: escapeCharacter(pico.intervention_must),
    fieldName: 'ml_i',
    boolType: 'must',
    operator: 'AND',
  });
  query.push({
    queryString: escapeCharacter(pico.population_at_least),
    fieldName: 'ml_p',
    boolType: 'should',
    operator: 'OR',
  });
  query.push({
    queryString: escapeCharacter(pico.intervention_at_least),
    fieldName: 'ml_i',
    boolType: 'should',
    operator: 'OR',
  });
  query.push({
    queryString: escapeCharacter(pico.population_not_include),
    fieldName: 'ml_p',
    boolType: 'must_not',
    operator: 'AND',
  });
  query.push({
    queryString: escapeCharacter(pico.intervention_not_include),
    fieldName: 'ml_i',
    boolType: 'must_not',
    operator: 'AND',
  });

  //const unquoted = JSON.stringify(query).replace(/"([^"]+)":/g, '$1:');

  const body = {
    search_queries: query,
    fuzziness,
    page_size,
    page,
    sort_by,
    sort_order,
    current_week,
    article_type: article_type_list.join(' '),
    summary: false,
  };

  await axios
    .post(`${apiBaseUrl}/search/pico`, body)
    .then((response) => {
      const { docs, size, summary } = response.data;

      docs.map((doc) => {
        doc.selected = false;
        return doc;
      });

      commit('SET_STATE_PROPERTY', { property: 'docs', value: docs });
      commit('SET_STATE_PROPERTY', {
        property: 'summary',
        value: summary,
      });
      commit('SET_STATE_PROPERTY', { property: 'numPages', value: Math.ceil(size / page_size) });
      commit('SET_STATE_PROPERTY', { property: 'numDocs', value: size });

      if (init) {
        commit('SET_STATE_PROPERTY', { property: 'showRecent', value: true });
        commit('SET_INIT_DOCS');
      } else {
        state.sort_order = sort_order;
        state.s_sort_by = sort_by;
        state.s_search_current_week = current_week;
      }

      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
      commit('SET_STATE_PROPERTY', {
        property: 'searchMobileSection',
        value: 'content',
      });
    })
    .catch((error) => {
      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
      Sentry.captureException(error);
    });
};

export const getQueries = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', { property: 'loading', value: true });
  axios
    .get(`${apiBaseUrl}/search/queries`)
    .then((response) => {
      const { docs } = response.data;
      let uniqueDisciplines = [];
      docs.forEach((doc) => {
        if (!uniqueDisciplines.includes(doc.discipline)) {
          uniqueDisciplines.push(doc.discipline);
        }
      });
      uniqueDisciplines = uniqueDisciplines.sort();

      commit('SET_STATE_PROPERTY', { property: 'uniqueDisciplines', value: uniqueDisciplines });
      commit('SET_STATE_PROPERTY', { property: 'queries', value: docs });

      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
    })
    .catch((error) => {
      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
      Sentry.captureException(error);
    });
};

export const getTopics = async (
  { commit, state },
  { init, qids, page, sort_order, sort_by, current_week, article_type_list }
) => {
  commit('SET_STATE_PROPERTY', { property: 'loading', value: true });
  commit('SET_STATE_PROPERTY', { property: 'showRecent', value: false });

  state.article_type_list = article_type_list;
  state.current_qids = qids;
  state.s_search_type = 'category';

  // const unquoted = JSON.stringify(qids).replace(/"([^"]+)":/g, '$1:');

  const body = {
    qids,
    fuzziness,
    page_size,
    page,
    sort_by,
    sort_order,
    current_week,
    article_type: article_type_list.join(' '),
    summary: false,
  };

  axios
    .post(`${apiBaseUrl}/search/category`, body)
    .then((response) => {
      const { docs, size, summary } = response.data;

      docs.map((doc) => {
        doc.selected = false;
        return doc;
      });

      commit('SET_STATE_PROPERTY', { property: 'docs', value: docs });
      commit('SET_STATE_PROPERTY', {
        property: 'summary',
        value: summary,
      });
      commit('SET_STATE_PROPERTY', { property: 'numPages', value: Math.ceil(size / page_size) });
      commit('SET_STATE_PROPERTY', { property: 'numDocs', value: size });

      if (init) {
        commit('SET_STATE_PROPERTY', { property: 'showRecent', value: true });
        commit('SET_INIT_DOCS');
      } else {
        state.sort_order = sort_order;
        state.s_sort_by = sort_by;
        state.s_search_current_week = current_week;
      }

      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
      commit('SET_STATE_PROPERTY', {
        property: 'searchMobileSection',
        value: 'content',
      });
    })
    .catch((error) => {
      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
      Sentry.captureException(error);
    });
};

export const purgeRecords = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', {
    property: 'docs',
    value: [],
  });
  commit('SET_STATE_PROPERTY', { property: 'showRecent', value: true });
  commit('SET_STATE_PROPERTY', { property: 's_page', value: 1 });
  commit('SET_STATE_PROPERTY', { property: 'summary', value: '' });
  commit('SET_STATE_PROPERTY', { property: 'numPages', value: 0 });
  commit('SET_STATE_PROPERTY', { property: 'numDocs', value: 0 });
};

export const searchPubmed = async (
  { commit, state },
  { init, pubmed_query, page, article_type_list, current_week, sort_by, sort_order }
) => {
  commit('SET_STATE_PROPERTY', { property: 'loading', value: true });
  commit('SET_STATE_PROPERTY', { property: 'showRecent', value: false });

  state.article_type_list = article_type_list;

  state.current_pubmed = pubmed_query;
  state.s_search_type = 'pubmed';

  // const unquoted = JSON.stringify(pubmed_query).replace(/"([^"]+)":/g, '$1:');
  // console.log(pubmed_query);
  // console.log(unquoted);

  const body = {
    search_query: pubmed_query,
    page_size,
    page,
    article_type: article_type_list.join(' '),
    current_week,
    sort_by,
    sort_order,
    summary: false,
  };

  await axios
    .post(`${apiBaseUrl}/search/pubmed`, body)
    .then((response) => {
      const { docs, size, summary } = response.data;

      docs.map((doc) => {
        doc.selected = false;
        return doc;
      });

      commit('SET_STATE_PROPERTY', { property: 'docs', value: docs });
      commit('SET_STATE_PROPERTY', {
        property: 'summary',
        value: summary,
      });
      commit('SET_STATE_PROPERTY', { property: 'numPages', value: Math.ceil(size / page_size) });
      commit('SET_STATE_PROPERTY', { property: 'numDocs', value: size });

      if (init) {
        commit('SET_STATE_PROPERTY', { property: 'showRecent', value: true });
        commit('SET_INIT_DOCS');
      } else {
        state.sort_order = sort_order;
        state.s_sort_by = sort_by;
        state.s_search_current_week = current_week;
      }

      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
      commit('SET_STATE_PROPERTY', {
        property: 'searchMobileSection',
        value: 'content',
      });
    })
    .catch((error) => {
      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
      Sentry.captureException(error);
    });
};

export const registerClick = async (_, { rid }) => {
  const query = `{
    registerClick(rid: "${rid}")
  }`;

  axios.post(graphqlUrl, { query }, await getAuthenticatedHeaders());
};

export const getQuery = async ({ commit, dispatch }, { qid }) => {
  commit('SET_STATE_PROPERTY', { property: 'loading', value: true });

  const query = `{
        loadQueries(qid:"${qid}") {
          query {
            intervention_at_least
            intervention_must
            intervention_not_include
            population_at_least
            population_must
            population_not_include
            pubmed_query
            qids
          }
          query_type
          sort_by
          sort_order
          title
          email
          institution
          article_type
          id
      }
    }`;

  axios
    .post(graphqlUrl, { query }, await getAuthenticatedHeaders())
    .then((response) => {
      const currentQueries = response.data.data.loadQueries;

      commit('SET_STATE_PROPERTY', {
        property: 's_article_type',
        value: currentQueries.article_type.split(' '),
      });
      commit('SET_STATE_PROPERTY', { property: 'title', value: currentQueries.title });
      commit('SET_STATE_PROPERTY', { property: 'email', value: currentQueries.email });
      commit('SET_STATE_PROPERTY', { property: 'institution', value: currentQueries.institution });
      commit('SET_STATE_PROPERTY', { property: 's_is_shown', value: false });

      commit('SET_STATE_PROPERTY', { property: 'searchType', value: currentQueries.query_type });
      commit('SET_STATE_PROPERTY', { property: 'sortBy', value: currentQueries.sort_by });
      commit('SET_STATE_PROPERTY', { property: 'sortOrder', value: currentQueries.sort_order });

      commit('SET_STATE_PROPERTY', { property: 'currentPico', value: currentQueries.query });
      commit('SET_STATE_PROPERTY', {
        property: 'currentPubmed',
        value: currentQueries.query.pubmed_query,
      });
      commit('SET_STATE_PROPERTY', { property: 'currentQids', value: currentQueries.query.qids });

      dispatch('loadNextPage', { page: 1 });
    })
    .catch((error) => {
      commit('SET_STATE_PROPERTY', { property: 'loading', value: false });
      Sentry.captureException(error);
    });
};
