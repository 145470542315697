<template>
  <div
    class="header"
    :class="{
      'header--mobile': mobile,
      'header--main': main,
    }">
    <PageHeader v-if="chip" :title="chip" chip />
    <PageHeader v-if="title" class="header__title" :title="title" :h="main ? 1 : 3" />
    <PageHeader
      v-if="!chip && !main && subtitle"
      class="header__subtitle"
      :title="subtitle"
      :h="3" />
  </div>
</template>

<script setup>
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();

defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    default: '',
  },
  chip: {
    type: String,
    default: '',
  },
  main: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  gap: rem(12px);
  &__title {
    :deep() {
      & > * {
        @include text16;
      }
    }
  }
  &__subtitle {
    :deep() {
      & > * {
        @include text40;
      }
    }
  }
  &--main & {
    &__title {
      :deep() {
        & > * {
          @include text40;
        }
      }
    }
  }
  &--mobile & {
    &__title {
      :deep() {
        & > * {
          @include text14;
        }
      }
    }
    &__subtitle {
      :deep() {
        & > * {
          @include text28;
        }
      }
    }
  }
  &--mobile.header--main & {
    &__title {
      :deep() {
        & > * {
          @include text28;
        }
      }
    }
  }
}
</style>
