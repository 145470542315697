<template>
  <RouterLink class="link" :to="{ name: 'UserProfile' }">
    <v-card-text>
      <div class="mx-auto text-center">
        <v-avatar :color="avatarColor">
          <span class="avatar-text">{{ initials }}</span>
        </v-avatar>
        <div v-if="displayName && hasName" class="user-menu-username-container">
          <v-list-item-title class="user-menu-username">{{ displayName }}</v-list-item-title>
          <v-icon size="small" icon="mdi-pencil-outline"></v-icon>
        </div>

        <v-list-item-title class="user-menu-card-text-caption">{{ email }}</v-list-item-title>

        <div v-if="!isPayingUser && !isSuperAdmin" class="user-menu-paying-button">
          <UpgradeButton />
        </div>
      </div>
    </v-card-text>
  </RouterLink>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import UpgradeButton from '@/features/Header/components/UpgradeButton.vue';
import { getColorFromHash } from '@/styles/stringToColor';

const store = useStore();

const isSuperAdmin = computed(() => store.getters.isSuperAdmin);
const displayName = computed(() => store.getters.displayName);
const hasName = computed(
  () => displayName.value && displayName.value !== store.state.userProfile?.email
);
const isPayingUser = computed(() => store.getters.isPaying);
const email = computed(() => store.state.userProfile?.email);
const initials = computed(
  () =>
    (store.state.userProfile?.firstName?.charAt(0)?.toUpperCase() ||
      email?.value?.charAt(0)?.toUpperCase() ||
      '') + (store.state.userProfile?.lastName?.charAt(0)?.toUpperCase() || '')
);

const avatarColor = computed(() =>
  getColorFromHash(
    store.state.userProfile?.firstName || '' + store.state.userProfile?.lastName || ''
  )
);
</script>

<style lang="scss" scoped>
.user-menu-username {
  font-weight: 500;
}

.link {
  text-decoration: none;
  color: inherit;
}
.user-menu-card-text-caption {
  @include text12($font-weight: 400);
}
.user-menu-username-container {
  display: flex;
  justify-content: center;
}
.user-menu-paying-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 10px;
}
.avatar-text {
  @include text18($font-weight: 400);
}
</style>
