import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { supportedInterfaceLanguageKeys } from '@/features/Settings/utils';

export const userInterfaceLanguageOptions = () => {
  const { t } = useI18n();
  const options = computed(() =>
    supportedInterfaceLanguageKeys.map((code) => ({
      value: code,
      label: t(`languages.${code}`),
    }))
  );
  return options;
};
