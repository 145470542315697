import { createI18n } from 'vue-i18n';
import { messages } from '../assets/lang/index.js';
import store from '../store/index.js';

const i18n = createI18n({
  legacy: false,
  locale: store.state.locale || 'en',
  fallbackLocale: 'en',
  messages,
  warnHtmlMessage: false,
});

export default i18n;
