<template>
  <v-list class="nav-list" nav dense active-class="nav-list__tab--active">
    <template v-for="(tab, i) in list" :key="i">
      <v-list-item
        v-if="!tab.hide"
        :to="tab.to"
        v-on="tab.listeners || {}"
        class="nav-list__tab"
        :class="[
          tab.class,
          {
            'nav-list__tab--mismatch': tab.mismatch,
            'nav-list__tab--button': tab.button,
          },
        ]"
        rounded="lg"
        variant="flat"
        :base-color="tab.color || 'bg-darken-1'"
        color="primary"
        :exact="!!tab.exact"
        density="compact"
        v-bind="tab.props?.()"
        @click="emit('navigate')">
        <div class="nav-list__tab-icon-wrapper">
          <inline-svg
            class="nav-list__tab-icon text-primary"
            :src="tab.icon"
            fill="currentColor"
            aria-label="icon" />
        </div>
        <span class="nav-list__tab-label">
          {{ tab.label }}
        </span>
      </v-list-item>
    </template>
  </v-list>
</template>

<script setup>
defineProps({
  list: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['navigate']);
</script>

<style lang="scss" scoped>
.nav-list {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: rem(16px);
  &__tabs-group {
    width: 100%;
  }
  &__tab {
    margin: 0 !important;
    padding: rem(14px) rem(16px) !important;
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(44px);
    font-size: rem(14px);
    font-weight: 500;
    line-height: rem(20px);
    :deep() {
      .v-list-item__content {
        display: flex;
        align-items: center;
        gap: rem(16px);
      }
      .nav-list__tab-label {
        color: $color-text;
      }
    }
    &--active {
      .nav-list__tab-icon {
        color: $color-bg !important;
      }
      .nav-list__tab-label {
        color: $color-bg;
      }
    }
    &--mismatch {
      background-color: $color-bg-darken-1 !important;
    }
    &--button {
      text-decoration: none;
      :deep() {
        .nav-list__tab-label {
          color: $color-bg;
        }
      }
    }
  }
  &__tab-icon-wrapper {
    display: flex;
    align-items: center;
  }
}
</style>
