/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as Sentry from '@sentry/browser';

// filters[posts][publishedAt][$notNull]=true&pagination[posts][pageSize]=1&pagination[posts][page]=1
/**
 * Fetches the chat history list from the server.
 * @param {Object} context - The Vuex context object.
 */
const authToken =
  'Bearer abfa8ff13d2adbc27310a67c3d5604dc229a3dde6284c9fafe41d705ed6079b0832f2e5f03aed990ae740526b96373930dfdfbb60d6d67540906315127a2d8aff936f5206f56a4dfcdbf05f005ffa1d124d0fe3915e983a82c4769e3ebc116837d1047f1cf30f930d71871a381c05daf29e202d31f3dc039edb803c3677b5d0c';

const fetchArticle = (id) =>
  axios.get(`https://cms.evidencehunt.com/api/posts/${id}`, {
    headers: {
      Authorization: authToken,
    },
    params: {
      populate: 'category,author',
    },
  });

export const getBlogPosts = async ({ commit }, payload) => {
  const { page, pageSize, search = '' } = payload;

  const requestConfig = {
    default: async () => {
      const response = await axios.get('https://cms.evidencehunt.com/api/posts', {
        headers: {
          Authorization: authToken,
        },
        params: {
          'pagination[pageSize]': pageSize,
          'pagination[page]': page,
          populate: 'category,author',
        },
      });
      return response.data;
    },
    search: async () => {
      const response = await axios.get('https://cms.evidencehunt.com/api/fuzzy-search/search', {
        headers: {
          Authorization: authToken,
        },
        params: {
          query: search,
          'pagination[posts][pageSize]': pageSize,
          'pagination[posts][page]': page,
          'filters[posts][publishedAt][$notNull]': true,
        },
      });

      const { posts } = response.data;

      const populateReqList = posts.data.map((el) => async () => {
        const article = await fetchArticle(el.id);
        return article.data.data;
      });

      const postDetails = await Promise.all(populateReqList.map((f) => f()));
      posts.data = postDetails;
      return posts;
    },
  };

  const reqMethod = search ? requestConfig.search : requestConfig.default;

  await reqMethod()
    .then((res) => {
      commit('SET_STATE_PROPERTY', {
        property: 'blog_list',
        value: res,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const resetBlogPosts = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', {
    property: 'blog_list',
    value: null,
  });
};

export const getBlogArticle = async ({ commit }, payload) => {
  const { id } = payload;
  await fetchArticle(id)
    .then((response) => {
      commit('SET_STATE_PROPERTY', {
        property: 'blog_article',
        value: response.data,
      });
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const resetBlogArticle = async ({ commit }) => {
  commit('SET_STATE_PROPERTY', {
    property: 'blog_article',
    value: null,
  });
};
