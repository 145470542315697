<template>
  <BaseModal width="auto" class="contact-modal" :title="t('about.title_5')" v-model="model">
    <EnterpriseContactForm />
  </BaseModal>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import EnterpriseContactForm from '@/features/EnterpriseContactForm.vue';
import BaseModal from '@/components/BaseModal';

const store = useStore();
const { t } = useI18n();

const model = computed({
  get: () => store.state.enterpriseContactModal,
  set: (val) => {
    store.commit('SET_STATE_PROPERTY', {
      property: 'enterpriseContactModal',
      value: val,
    });
  },
});
</script>

<style lang="scss">
.contact-modal {
  .contact-form__btn {
    width: 100%;
    max-width: unset !important;
    margin-top: auto;
  }
}
</style>
