const animatedScrollObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.dataset.intersected = '';
      animatedScrollObserver.unobserve(entry.target);
    }
  });
});
export default {
  mounted(el, binding) {
    const { value = 'scale-children' } = binding;
    el.classList.add('appear-animation');
    el.classList.add(`appear-animation--${value}`);
    animatedScrollObserver.observe(el);
  },
};
