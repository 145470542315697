<template>
  <div ref="loaderEl" class="loader text-center">
    <v-progress-circular :size="size" :width="width" indeterminate :color="color" />
  </div>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
  size: {
    type: Number,
    default: 50,
  },
  width: {
    type: Number,
    default: 4,
  },
  color: {
    type: String,
    default: 'primary',
  },
});

const loaderEl = ref(null);
defineExpose({
  loaderEl,
});
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(12px);
}
</style>
