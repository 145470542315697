<template>
  <div ref="searchLoaderEl">
    <v-container>
      <v-skeleton-loader class="mx-auto" max-width="800" type="article"></v-skeleton-loader>
    </v-container>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const searchLoaderEl = ref(null);
defineExpose({
  searchLoaderEl,
});
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: rem(12px);
}
</style>
