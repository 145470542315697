<template>
  <div class="nav-wrapper">
    <v-app-bar color="bg" class="nav nav--mobile" :height="45" elevation="0" absolute>
      <template v-if="!noPrepend" #prepend>
        <div class="my-auto">
          <slot name="prepend">
            <ExpandButton
              v-if="back || backCallback"
              class="bg-bg-darken-1"
              width="24"
              height="24"
              size="small"
              :right="false"
              v-bind="backProps"
              v-on="backListeners" />
            <v-app-bar-nav-icon
              v-else
              density="compact"
              @click="drawer = !drawer"
              class="primary" />
          </slot>
        </div>
      </template>

      <div class="nav__content text-text">
        <slot />
      </div>

      <template v-if="!noAppend" #append>
        <div class="nav__right">
          <slot name="append">
            <HeaderTabs :user="userProfile" />
          </slot>
        </div>
      </template>
    </v-app-bar>
    <MobileSidebar v-model="drawer" @navigate="drawer = false" />
  </div>
</template>

<script setup>
import { computed, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import { HeaderTabs, MobileSidebar } from '@/features/Header';
import ExpandButton from '@/components/ExpandButton.vue';

const store = useStore();

const props = defineProps({
  noPrepend: {
    type: Boolean,
    default: false,
  },
  noAppend: {
    type: Boolean,
    default: false,
  },
  back: {
    type: [String, Object],
    default: undefined,
  },
  backCallback: {
    type: Function,
    default: undefined,
  },
});

const { back, backCallback } = toRefs(props);

const drawer = ref(false);

// const drawer = computed({
//   get: () => store.state.mobileSideMenu,
//   set: (val) => {
//     store.commit('SET_STATE_PROPERTY', {
//       property: 'mobileSideMenu',
//       value: val,
//     });
//   },
// });

const userProfile = computed(() => store.state.userProfile);

const backProps = computed(() => {
  const result = {};
  if (back.value) result.to = back.value;
  return result;
});
const backListeners = computed(() => {
  const result = {};
  if (backCallback.value) result.click = backCallback.value;
  return result;
});
</script>

<style lang="scss" scoped>
.nav {
  border-bottom: 1px solid $color-secondary;
  &--mobile {
    padding: 0 rem(16px);
  }
  &__content {
    font-size: rem(14px);
    font-weight: 500;
    line-height: rem(20px);
    text-align: center;
  }
  &__right {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    gap: rem(12px);
    flex-grow: 1;
    justify-content: flex-end;
    :deep() {
      .v-btn {
        text-transform: capitalize;
      }
    }
  }
  &__controls {
    display: flex;
    align-items: center;
    gap: rem(5px);
    .login-btn {
      margin-left: rem(78px);
    }
  }
}
:deep() {
  .v-toolbar-title {
    &__placeholder {
      display: flex;
    }
  }
  .v-toolbar {
    &__content {
      gap: rem(12px);
      height: 45px;
      display: grid;
      grid-template-columns: minmax(min-content, 1fr) 2fr minmax(min-content, 1fr);
    }
    &__prepend {
      margin: 0;
      .expand-button {
        color: $color-text !important;
      }
    }
    &__append {
      margin-right: 0;
      flex-grow: 1;
      justify-content: flex-end;
      .nav__right {
        position: absolute;
        right: 0;
      }
    }
  }
}
</style>
