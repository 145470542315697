<template>
  <SwitchButton
    v-model="keepFormatModel"
    :label="t('answerFormatting.alwaysOn')"
    style="margin-left: auto"
    :is-text-form-color="false" />
</template>
<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import SwitchButton from '@/components/form/SwitchButton';

const { t } = useI18n();
const store = useStore();

const keepFormatModel = computed({
  get: () => store.state.keepChatAnswerFormat,
  set: (val) => {
    store.commit('SET_STATE_PROPERTY', { property: 'keepChatAnswerFormat', value: val });
  },
});
onMounted(() => {
  store.commit('SET_STATE_PROPERTY', { property: 'keepChatAnswerFormat', value: false });
});
</script>

<style lang="scss" scoped>
.period--selector {
  &--value {
    flex-grow: 1;
  }

  :deep() {
    .v-select__selection-text {
      font-weight: 400;
    }
  }
}
</style>
