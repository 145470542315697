<template>
  <v-footer padless class="footer text-text bg-bg">
    <div class="footer__left">
      {{ copyrightText }}
      <span v-if="routeName === 'UserProfile'">Version: {{ gitHash }}</span>
    </div>
    <div class="footer__center">
      <v-btn
        v-for="link in policyPages"
        :key="link.text"
        class="footer__link"
        variant="text"
        text
        density="compact"
        @click="onPolicyPageClick(link)">
        {{ link.text }}
      </v-btn>
    </div>
    <div class="footer__right">
      <IconLink
        v-for="link in socialLinks"
        :key="link.name"
        :icon="link.icon"
        :name="link.name"
        :link="link.route"
        @click="trackSocialLinkClicked(link)" />
    </div>
  </v-footer>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { socialLinks } from '@/utils/social';
import IconLink from '@/components/IconLink.vue';
import usePolicyPages, { useNavigate } from '@/composables/policyPages';
import { getRouteName } from '@/utils';

const store = useStore();
const navigate = useNavigate();
const policyPages = usePolicyPages();
const route = useRoute();

const trackSocialLinkClicked = (link) => {
  const routeName = getRouteName(route, true);
  store.dispatch('trackEvent', {
    event: 'SocialMediaIconClicked',
    values: {
      page: routeName,
      content: link.id,
      location: 'footer',
    },
  });
};

const onPolicyPageClick = (link) => {
  navigate(link);
};

const copyrightText = computed(() => `Copyright ${new Date().getFullYear()} EvidenceHunt.`);
const gitHash = computed(() => process.env.VUE_APP_GIT_HASH);
const routeName = computed(() => route.name);
</script>

<style lang="scss" scoped>
.footer {
  // position: fixed!important;
  margin-top: auto;
  font-size: rem(12px);
  padding: 0 rem(24px);
  min-height: rem(32px) !important;
  flex-grow: 0;
  flex-shrink: 0;
  border-top: 1px solid rgb(var(--v-theme-secondary));
  display: flex;
  align-items: center;
  gap: rem(16px);
  justify-content: space-between;
  &__link {
    text-transform: none;
    font-size: rem(12px);
  }
  &__right {
    display: flex;
    gap: rem(16px);
  }
  &__center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
