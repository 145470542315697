export const config = {
  apiBaseUrl: `${process.env.VUE_APP_API_URL}`,
}

config.graphqlUrl = `${config.apiBaseUrl}/graphql`;
config.graphqlUrlPublic = `${config.apiBaseUrl}/public/graphql`;


export const page_size = 100;
export const fuzziness = 1;
