<template>
  <v-tabs
    light
    class="header-tabs"
    :class="{
      'header-tabs--compact': !mdAndUp,
    }"
    color="primary"
    fixed-tabs
    selected-class="header-tab--selected"
    :mandatory="false"
    v-model="tab">
    <template v-if="!mobile">
      <HeaderTab
        v-for="(item, key) in leafTabs"
        :key="key"
        class="header-tab"
        :class="{
          'header-tab--last-main': key === tabs.length - 1,
        }"
        :to="item.to"
        :exact="!!item.exact"
        :icon="item.icon"
        :active="tab === key">
        {{ item.label }}
      </HeaderTab>
      <ParentHeaderTab
        v-for="item in parentTabs"
        :key="item.label"
        :label="item.label"
        :children="item.children" />
    </template>
  </v-tabs>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import HeaderTab from '@/features/Header/components/HeaderTab';
import ParentHeaderTab from '@/features/Header/components/ParentHeaderTab.vue';
import { CAREERS_LINK } from '@/utils/externalLinks';

const { t } = useI18n();
const { mobile, mdAndUp } = useDisplay();

const tab = ref(null);

const store = useStore();
/**Pricing should be visible for only logged out and free users */
const hidePricing = computed(
  () =>
    store.getters.isSandbox ||
    store.getters.isPaying ||
    store.state.organization ||
    store.getters.isSuperAdmin
);

const tabs = computed(() => {
  const defaultTabs = [
    {
      label: t('global.chat'),
      to: {
        name: 'Chat',
      },
      exact: true,
    },
    {
      label: t('global.search'),
      to: {
        name: 'Search',
      },
    },
    {
      label: t('global.pricing'),
      to: {
        name: 'Pricing',
      },
    },
    {
      label: t('global.about'),
      to: {
        name: 'About',
      },
      children: [
        {
          label: t('global.whoWeAre'),
          to: {
            name: 'About',
          },
        },
        {
          label: t('global.knowledgeBase'),
          to: {
            name: 'Knowledge Base',
          },
        },
        {
          label: t('footer.careers'),
          href: CAREERS_LINK,
        },
      ],
    },
  ];

  if (hidePricing.value) {
    return defaultTabs.filter((tb) => tb.label !== t('global.pricing'));
  }

  return defaultTabs;
});

const parentTabs = computed(() => tabs.value.filter((item) => !!item.children));
const leafTabs = computed(() => tabs.value.filter((item) => !item.children));
</script>

<style lang="scss" scoped>
.header-tabs {
  flex-shrink: 1;
  .settings-tab {
    .settings-btn {
      pointer-events: none;
    }
  }
  .header-tab {
    &--settings {
      margin-left: rem(56px);
      padding: 0 rem(5px);
      :deep() {
        & > .v-btn__overlay {
          display: none;
        }
      }
    }
  }
  :deep() {
    .v-slide-group__content {
      gap: rem(32px);
    }
  }
  &--compact {
    .header-tab {
      &--settings {
        margin-left: rem(16px);
      }
    }
    :deep() {
      .v-slide-group__content {
        gap: rem(8px);
      }
    }
  }
}
</style>
