<template>
  <div
    class="page-header"
    :class="{
      'page-header--mb': mb,
    }">
    <component
      v-if="!chip"
      :is="tag"
      class="page-header__title text-text"
      :class="[
        headerClass,
        {
          'page-header__title--no-margin': noMargin,
        },
      ]">
      {{ title }}
    </component>
    <Chip :class="headerClass" v-else color="primary" :label="title" />
    <slot name="append" />
  </div>
</template>

<script setup>
import { computed, toRefs, defineAsyncComponent } from 'vue';

const Chip = defineAsyncComponent(() => import('@/components/Chip.vue'));

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  h: {
    type: Number,
    default: 2,
  },
  headerClass: {
    type: [String, Array, Object],
    default: undefined,
  },
  noMargin: {
    type: Boolean,
    default: false,
  },
  mb: {
    type: Boolean,
    default: false,
  },
  chip: {
    type: Boolean,
    default: false,
  },
});

const { h } = toRefs(props);

const tag = computed(() => `h${h.value}`);
</script>

<style lang="scss" scoped>
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(8px);
  flex-shrink: 0;
  &--mb {
    margin-bottom: rem(20px);
  }
  &__title {
    margin-right: auto;
    font-weight: 500;
    white-space: break-spaces;
    &--no-margin {
      margin: 0 !important;
    }
  }
}
</style>
