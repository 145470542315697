<template>
  <v-main class="main bg-bg-darken-1 text-text" transition="scroll-x-reverse-transition">
    <slot name="prepend" />
    <div
      class="main__content"
      :class="{
        'main__content--sm': !mdAndUp,
        'main__content--no-padding-x': noPaddingX,
        'main__content--no-padding-y': noPaddingY,
      }">
      <div v-if="title || hasSlot('header-controls')" class="main__header">
        <h2 class="main__header-title text-text">
          {{ title }}
        </h2>
        <slot name="header-controls" />
      </div>
      <slot />
    </div>
    <slot name="append" />
  </v-main>
</template>

<script setup>
import { useSlots } from 'vue';
import { useDisplay } from 'vuetify';

const { mdAndUp } = useDisplay();

defineProps({
  title: {
    type: String,
    default: '',
  },
  noPaddingX: {
    type: Boolean,
    default: false,
  },
  noPaddingY: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();
const hasSlot = (name = 'default') => !!slots[name];
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(8px);
  }
  &__header-title {
    font-size: rem(20px);
    font-weight: 500;
    line-height: rem(24px);
    margin-right: auto;
  }
  &__content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    flex-grow: 1;
    padding: rem(40px);
    &--sm {
      padding: rem(24px);
    }
    &--no-padding-x {
      padding-left: 0;
      padding-right: 0;
    }
    &--no-padding-y {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
