<template>
  <v-form class="contact-form" :action="formActionUrl" v-model="isValid" method="POST">
    <div class="contact-form__fields">
      <div class="contact-form__row">
        <BaseInput
          class="contact-form__field"
          :label="t('fields.firstName')"
          v-model="queryData.first_name"
          :rules="[rules.required()]"
          name="First name"
          dark />
        <BaseInput
          class="contact-form__field"
          :label="t('fields.lastName')"
          v-model="queryData.second_name"
          :rules="[rules.required()]"
          name="Last name"
          dark />
      </div>
      <div class="contact-form__row">
        <BaseInput
          class="contact-form__field"
          :label="t('fields.email')"
          v-model="queryData.email"
          :rules="[rules.required(t('validations.emailRequired')), rules.email()]"
          type="email"
          name="Email"
          dark />
      </div>
      <div class="contact-form__row">
        <BaseTextarea
          class="contact-form__field"
          :label="t('about.howCanWeHelp')"
          v-model="queryData.query"
          :rules="[rules.required()]"
          :placeholder="t('fields.startTyping')"
          name="Query"
          dark />
      </div>
    </div>
    <BaseButton
      class="contact-form__btn"
      size="large"
      color="primary"
      type="submit"
      :disabled="!isValid"
      :max="mobile">
      {{ t('about.sendMsg') }}
    </BaseButton>
  </v-form>
</template>

<script setup>
import { reactive, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useDisplay } from 'vuetify';
import useRules from '@/composables/validations';

import BaseInput from '@/components/form/BaseInput.vue';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import BaseButton from '@/components/BaseButton.vue';

const { t } = useI18n();
const rules = useRules();
const store = useStore();
const { mobile } = useDisplay();

const queryData = reactive({
  first_name: '',
  second_name: '',
  email: '',
  query: '',
});

const isPro = computed(() => store.getters.isPro);
const formActionUrl = computed(
  () => `https://formspree.io/f/${isPro.value ? 'mdoqzbwv' : 'mzbndeke'}`
);

const isValid = ref(false);
</script>

<style lang="scss" scoped>
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: rem(24px);
  min-height: rem(440px);
  &__fields {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: rem(2px);
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: rem(4px) rem(32px);
  }
  &__field {
    min-width: rem(220px);
    flex: 1 1 40%;
  }
}
</style>
