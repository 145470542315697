<template>
  <v-divider :thickness="1" style="opacity: 80%" />
  <v-list-subheader class="user-menu-subheader">{{ t('userMenu.superAdmin') }}</v-list-subheader>
  <RouterLink class="link" :to="{ name: 'Organizations' }">
    <v-list-item key="org-switcher-orgs" value="org-switcher-orgs" color="primary" variant="text">
      <template #prepend>
        <v-icon :icon="'mdi-plus'" style="margin-right: -24px" color="black"></v-icon>
      </template>
      <v-list-item-title> {{ t('userMenu.createOrganization') }} </v-list-item-title>
    </v-list-item>
  </RouterLink>

  <RouterLink class="link" :to="{ name: 'Organizations' }">
    <v-list-item key="org-switcher-orgs" value="org-switcher-orgs" color="primary" variant="text">
      <template #prepend>
        <v-icon :icon="'mdi-domain'" style="margin-right: -24px" color="black"></v-icon>
      </template>
      <v-list-item-title>
        {{ t('global.organizations') }}
      </v-list-item-title>
    </v-list-item>
  </RouterLink>

  <RouterLink class="link" :to="{ name: 'Users' }">
    <v-list-item key="org-switcher-users" value="org-switcher-users" color="primary" variant="text">
      <template #prepend>
        <v-icon :icon="'mdi-account-multiple'" style="margin-right: -24px" color="black"></v-icon>
      </template>
      <v-list-item-title>
        {{ t('global.users') }}
      </v-list-item-title>
    </v-list-item>
  </RouterLink>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
<style lang="scss" scoped>
.link {
  text-decoration: none;
  color: inherit;
}
.user-menu-subheader {
  text-transform: uppercase;
  font-size: rem(10px);
}
</style>
