<template>
  <v-card
    class="content-card"
    :class="{
      'content-card--bordered': border,
      'content-card--mobile': mobile,
    }"
    elevation="0"
    flat
    color="bg">
    <slot />
  </v-card>
</template>

<script setup>
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay();

defineProps({
  border: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" scoped>
.content-card {
  border-radius: rem(16px);
  padding: rem(32px);
  display: flex;
  flex-direction: column;
  border: 1px solid $color-bg;
  &--bordered {
    border: $border;
  }
  &--mobile {
    padding: rem(24px) rem(16px);
  }
}
</style>
