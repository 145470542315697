<template>
  <BaseIcon
    class="help-icon pa-0"
    :color="model ? 'secondary-darken-4' : 'grey'"
    rounded
    :icon="InfoIcon"
    full
    button
    @click.stop="model = true">
    <v-tooltip v-if="description" activator="parent" location="top" max-width="320" v-model="model">
      {{ description }}
    </v-tooltip>
  </BaseIcon>
</template>

<script setup>
import { ref } from 'vue';
import BaseIcon from '@/components/BaseIcon.vue';
import InfoIcon from '@/assets/icons/info-mark.svg';

defineProps({
  description: {
    type: String,
    default: '',
  },
});

const model = ref(false);

defineExpose({
  model,
});
</script>

<style lang="scss" scoped>
.help-icon {
  min-width: unset;
  width: rem(12px);
  height: rem(12px);
}
</style>
