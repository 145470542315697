<template>
  <div ref="loaderEl" class="loader text-center">
    <div class="half-circle-spinner">
      <div class="circle circle-1"></div>
      <div class="circle circle-2"></div>
      <BaseIcon :icon="LogoShortIcon" :size="64" style="margin-top: -2px" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import LogoShortIcon from '@/assets/icons/logo-spear-short.svg';
import BaseIcon from '@/components/BaseIcon.vue';

const loaderEl = ref(null);
defineExpose({
  loaderEl,
});
</script>

<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(12px);
}
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 15) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #0362ff;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #0362ff;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
