<template>
  <BaseModal :title="t('answerFormatting.title')" v-model="internalModalModel" :max-width="1000">
    <div class="container">
      <div class="description description__flex">
        <span>{{ t('answerFormatting.description_1_1') }}</span>
        <HelpIcon :description="t('answerFormatting.customTemplateHelp')" />
      </div>
      <ExpandableText :button-title="t('answerFormatting.examples')">
        <ul class="list description">
          <li>{{ t('answerFormatting.description_1_2') }}</li>
          <li>{{ t('answerFormatting.description_1_3') }}</li>
          <li>{{ t('answerFormatting.description_1_4') }}</li>
        </ul>
      </ExpandableText>
      <KeepFormatButton />
      <TokenInformativeBlock
        :hide-progressbar="true"
        :is-token-limit-exceeded="isTokenLimitExceeded"
        :current-token-percentage="currentTokenPercentage"
        :informative-message="t('chat.formatTokenLimitExceeded')" />
      <BaseTextarea
        rows="15"
        :disable-auto-grow="true"
        v-model="textAreaModel"
        class="textarea-bg" />
      <div :class="buttonContainerClasses">
        <div :class="leftButtonsContainerClasses">
          <BaseButton v-if="isChatAnswerFormatAlreadySet" color="secondary" @click="resetFormat">{{
            t('answerFormatting.resetFormat')
          }}</BaseButton>
        </div>
        <div :class="rightButtonsContainerClasses">
          <BaseButton
            variant="outlined"
            v-if="!isRegenerateInvisible"
            @click="regenerateAnswer"
            :disabled="isRegenerateDisabled"
            >{{ t('answerFormatting.regenerateAnswerWithFormat') }}</BaseButton
          >
          <BaseButton @click="setAnswerFormattingText" :disabled="isSetFormatDisabled">{{
            t('answerFormatting.setFormat')
          }}</BaseButton>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, watch } from 'vue';
import { useTokenizer } from '@/composables/tokens';
import BaseModal from '@/components/BaseModal';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import BaseButton from '@/components/BaseButton.vue';
import HelpIcon from '@/components/HelpIcon.vue';
import ExpandableText from '@/components/ExpandableText.vue';
import TokenInformativeBlock from '@/features/Chat/components/TokenInformativeBlock.vue';
import KeepFormatButton from '@/features/OutputTemplate/components/KeepFormatButton.vue';

const MAX_TOKEN_LIMIT = 1500;

const { t } = useI18n();
const router = useRouter();
const store = useStore();
const { countTokens, currentTokenPercentage, isTokenLimitExceeded } = useTokenizer();

const storedChatAnswerFormat = computed(() => store.getters.getChatAnswerFormat);
const isChatAnswerFormatAlreadySet = computed(() => !!storedChatAnswerFormat.value);
const chatSessionId = computed(() => store.state.chatSessionId);
const textAreaModel = computed({
  get: () => store.getters.getChatOutputTemplateInput,
  set: (val) => {
    store.commit('SET_STATE_PROPERTY', { property: 'chatOutputTemplateInput', value: val });
  },
});
const isUserChanging = computed(() => textAreaModel.value !== storedChatAnswerFormat.value);

const internalModalModel = computed({
  get: () => store.getters.getChatOutputTemplateModal,
  set: (val) => {
    store.commit('SET_STATE_PROPERTY', { property: 'chatOutputTemplateModal', value: val });
  },
});

const isSetFormatDisabled = computed(() => !isUserChanging.value || isTokenLimitExceeded.value);
const prevChatAnswerFormat = computed(() => store.getters.getPrevChatAnswerFormat);

const userProfile = computed(() => store.state.userProfile);
const chatNewMessage = computed(() => store.state.chatNewMessage);

const isEnglishUser = computed(() => store.getters.isEnglishUser);

const commonClasses = computed(() => ({
  actions_container__engels_mobile: isEnglishUser.value,
  actions_container__nederlands_mobile: !isEnglishUser.value,
}));

const buttonContainerClasses = computed(() => ({
  actions_container: true,
  ...commonClasses.value,
}));
const rightButtonsContainerClasses = computed(() => ({
  'actions_container__right-buttons': true,
  ...commonClasses.value,
}));
const leftButtonsContainerClasses = computed(() => ({
  'actions_container__left-buttons': true,
  ...commonClasses.value,
}));
const isRegenerateDisabled = computed(() => {
  return prevChatAnswerFormat.value === textAreaModel.value || isTokenLimitExceeded.value;
});

const isRegenerateInvisible = computed(() => store.getters.isNewChat);

const setAnswerFormattingText = () => {
  if (!textAreaModel.value) {
    resetFormat();
    return;
  }
  store.dispatch('trackEvent', {
    event: 'CustomTemplateSet',
    values: {
      chatSessionId: chatSessionId.value,
      customTemplate: textAreaModel.value,
    },
  });
  store.commit('SET_CHAT_ANSWER_FORMAT', { value: textAreaModel.value });
  internalModalModel.value = false;
};
const resetFormat = () => {
  store.commit('SET_STATE_PROPERTY', { property: 'chatOutputTemplateInput', value: '' });
  store.commit('SET_CHAT_ANSWER_FORMAT', { value: '' });
  store.commit('SET_STATE_PROPERTY', {
    property: 'prevChatAnswerFormat',
    value: '',
  });
  store.dispatch('trackEvent', {
    event: 'DefaultTemplateSet',
    values: {
      chatSessionId: chatSessionId.value,
    },
  });
  internalModalModel.value = false;
};
const regenerateAnswer = () => {
  if (!userProfile.value?.uid) {
    localStorage.setItem('authRedirectedUrl', router.currentRoute.value.path);
    if (chatNewMessage.value) {
      localStorage.setItem('chatNewMessage', chatNewMessage.value);
    }

    store.commit('SET_STATE_PROPERTY', {
      property: 'auth_redirected',
      value: true,
    });
    router.push({
      name: 'Register',
    });
  } else {
    if (storedChatAnswerFormat.value !== textAreaModel.value) {
      store.commit('SET_CHAT_ANSWER_FORMAT', { value: textAreaModel.value });
    }
    store.dispatch('regenerateMessage');
    store.dispatch('trackEvent', {
      event: 'regenerateMessageWithCustomTemplate',
      values: {
        chatSessionId: chatSessionId.value,
        lastQuestion: store.getters.getChatLastQuestion,
        lastAnswer: store.getters.getChatLastAnswer,
        customTemplate: textAreaModel.value,
      },
    });

    store.commit('SET_STATE_PROPERTY', {
      property: 'prevChatAnswerFormat',
      value: textAreaModel.value,
    });
  }

  internalModalModel.value = false;
};
watch(textAreaModel, (newVal) => {
  countTokens(newVal, MAX_TOKEN_LIMIT);
});
watch(
  [internalModalModel, textAreaModel, storedChatAnswerFormat],
  ([newValModel, newValInput, newValStoredFormat], [, , prevValStoredFormat]) => {
    if (!newValModel) {
      if (newValInput !== newValStoredFormat) {
        store.commit('SET_STATE_PROPERTY', {
          property: 'chatOutputTemplateInput',
          value: newValStoredFormat,
        });
      }
    }
    if (!newValModel && newValInput !== newValStoredFormat) {
      store.dispatch('trackEvent', {
        event: 'ClosedSettingFormat',
        values: {
          chatSessionId: chatSessionId.value,
        },
      });
      return;
    }
    if (!newValModel && newValInput === prevValStoredFormat && newValInput !== '') {
      store.dispatch('trackEvent', {
        event: 'ClosedSettingFormat',
        values: {
          chatSessionId: chatSessionId.value,
        },
      });
      return;
    }
    if (!newValModel && !newValInput && !newValStoredFormat && !prevValStoredFormat) {
      store.dispatch('trackEvent', {
        event: 'ClosedSettingFormat',
        values: {
          chatSessionId: chatSessionId.value,
        },
      });
      return;
    }
  }
);
</script>

<style lang="scss">
.container {
  display: flex;
  flex-direction: column;
  gap: rem(16px);
  margin-right: 4px;
}
.list {
  margin-inline: rem(32px);
  display: flex;
  flex-direction: column;
  gap: rem(4px);
}
.description {
  color: $color-secondary-darken-3;
  @include text14;
  &__flex {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(8px);
  }
}

.textarea-bg {
  & .v-field {
    background-color: rgb(var(--v-theme-bg-darken-1)) !important;
  }
}
.actions_container {
  display: flex;
  justify-content: space-between;
  gap: rem(16px);

  &__nederlands_mobile {
    @media screen and (max-width: 900px) {
      flex-direction: column;
      gap: rem(16px);
    }
  }
  &__engels_mobile {
    @media screen and (max-width: 790px) {
      flex-direction: column;
      gap: rem(16px);
    }
  }
  &__left-buttons {
    display: flex;
    gap: rem(16px);
  }
  &__right-buttons {
    display: flex;
    gap: rem(16px);
  }
}
</style>
