<template>
  <v-btn
    :ripple="false"
    @click="isOpen = !isOpen"
    variant="plain"
    :class="buttonClasses"
    :append-icon="isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
    >{{ buttonTitle }}
  </v-btn>
  <v-expand-transition>
    <div v-show="isOpen">
      <slot />
    </div>
  </v-expand-transition>
</template>

<script setup>
import { ref } from 'vue';
const isOpen = ref(false);

defineProps({
  buttonTitle: {
    type: String,
    required: true,
  },
  buttonClasses: {
    type: String,
    default: 'examples-button',
  },
});
</script>

<style lang="scss" scoped>
.examples-button {
  width: max-content;
  text-transform: capitalize;
  padding: 0px 0px !important;
  :deep() {
    .v-btn__content,
    .v-btn__append {
      color: $color-secondary-darken-3;
    }
  }
  &.v-btn--variant-plain {
    opacity: 1 !important;
  }
}
</style>
