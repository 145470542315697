<template>
  <CustomSwitchButton
    v-if="custom"
    class="switch-btn switch-btn--custom"
    v-model="model"
    :label="label"
    :options="options"
    :large="large"
    :active-color="activeColor"
    :color="color" />
  <v-switch
    v-else
    class="switch-btn switch-btn--base"
    :class="{ 'text-text-lighten-2': isTextFormColor }"
    v-model="model"
    hide-details
    inset
    :label="label"
    density="compact"
    :color="color" />
</template>

<script setup>
import { computed, toRefs, watch, ref, defineAsyncComponent } from 'vue';

const CustomSwitchButton = defineAsyncComponent(() => import('./CustomSwitchButton.vue'));

const props = defineProps({
  modelValue: {
    type: [Boolean, String],
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
  custom: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  activeColor: {
    type: String,
    default: 'primary-lighten-1',
  },
  color: {
    type: String,
    default: 'bg-darken-1',
  },
  isTextFormColor: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(['update:modelValue']);

const { modelValue, options } = toRefs(props);

// const computedValue = computed({
//   get: () => modelValue.value,
//   set(val) {
//     emit('update:modelValue', val);
//   },
// });

const initialValue = ref(modelValue.value);
// const model = ref(computedValue.value);
// watch(computedValue, (newVal) => {
//   model.value = newVal;
// });

const model = computed({
  get: () => initialValue.value,
  set(newVal) {
    initialValue.value = newVal;
    emit('update:modelValue', newVal);
  },
});

watch(modelValue, (newVal) => {
  initialValue.value = newVal;
});
</script>

<script>
export default {
  name: 'SwitchButton',
};
</script>

<style lang="scss" scoped>
.switch-btn-wrapper {
  padding: rem(4px);
  display: inline-flex;
  align-items: center;
  gap: rem(8px);
  border: 1px solid rgb(var(--v-theme-secondary));
}
.switch-btn {
  &--base {
    min-height: unset !important;
    height: rem(24px);
    :deep() {
      .v-input__control,
      .v-selection-control,
      .v-selection-control__wrapper {
        min-height: unset;
        height: 100%;
      }
      .v-switch__track {
        height: 100%;
        min-height: rem(24px);
        min-width: rem(36px);
        width: rem(40px);
        background-color: rgb(var(--v-theme-bg-darken-1));
        border: 1px solid rgb(var(--v-theme-secondary));
      }
      .v-switch__thumb {
        width: rem(20px);
        height: rem(20px);
      }
      .v-selection-control {
        .v-selection-control__input {
          width: rem(24px);
          height: rem(24px);
        }
        .v-switch__thumb {
          background-color: rgb(var(--v-theme-secondary));
        }
        &--dirty {
          .v-selection-control__input {
            // width: rem(28px);
            // height: rem(28px);
            transform: translateX(8px);
          }
          .v-switch__thumb {
            background-color: rgb(var(--v-theme-primary-lighten-1));
          }
        }
        .v-label {
          padding-inline-start: rem(8px);
          font-size: rem(12px);
          font-weight: 400;
          opacity: 1;
        }
      }
    }
  }
}
</style>
