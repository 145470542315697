import { auth } from './firebase';

async function getAuthenticatedHeaders(options = {}) {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    if (!options.headers) options.headers = {};
    options.headers['Authorization'] = `Bearer ${token}`;
  }
  return options;
}

async function getIdToken() {
  if (auth.currentUser) {
    return await auth.currentUser.getIdToken();
  } else {
    return '';
  }
}

export { getAuthenticatedHeaders, getIdToken };
