<template>
  <div ref="loaderEl" class="text-center">
    <span class="loader"></span>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const loaderEl = ref(null);
defineExpose({
  loaderEl,
});
</script>

<style lang="scss" scoped>
.loader {
  margin: rem(12px);
  width: 60px;
  height: 40px;
  position: relative;
  display: inline-block;
  --base-color: #68a1ff; /*use your base color*/
}
.loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 20px auto;
  position: relative;
  border: 3px solid #0362ff;
  border-radius: 50%;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  width: 6px;
  height: 24px;
  background: #68a1ff;
  transform: rotate(-45deg);
  position: absolute;
  bottom: -20px;
  left: 46px;
}

@keyframes animloader {
  0% {
    transform: translate(-10px, -10px);
  }
  25% {
    transform: translate(-10px, 10px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  75% {
    transform: translate(10px, -10px);
  }
  100% {
    transform: translate(-10px, -10px);
  }
}
</style>
