const stringToHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char; // Bitwise operation to mix the hash
    hash |= 0; // Convert to 32-bit integer
  }
  return hash;
};

export const getColorFromHash = (str) => {
  const hash = stringToHash(str);

  const r = (hash >> 16) & 0xff;
  const g = (hash >> 8) & 0xff;
  const b = hash & 0xff;

  const color = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;

  return color;
};
