<template>
  <component
    :is="button ? VBtn : VChip"
    class="base-icon"
    :class="{
      'base-icon--full': full,
      'base-icon--rounded': rounded,
      'base-icon--icon-size': iconSize,
      'bg-bg-darken-1': dark,
    }"
    icon
    flat
    variant="text"
    density="compact"
    size="x-small">
    <inline-svg
      class="base-icon__icon"
      :class="{
        [`text-${color}`]: color,
        'base-icon__icon--fill': fill,
        'base-icon__icon--stroke': stroke,
      }"
      :src="icon"
      :fill="fill ? 'currentColor' : 'transparent'"
      :stroke="stroke ? 'currentColor' : 'transparent'"
      aria-label="icon" />
    <slot />
  </component>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { VBtn, VChip } from 'vuetify/components';

const props = defineProps({
  button: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    required: true,
  },
  size: {
    type: Number,
    default: undefined,
  },
  iconSize: {
    type: Number,
    default: undefined,
  },
  fill: {
    type: Boolean,
    default: true,
  },
  stroke: {
    type: Boolean,
    default: false,
  },
  full: {
    type: Boolean,
    default: false,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  dark: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'primary',
  },
});

const { size, iconSize } = toRefs(props);

const computedSize = computed(() => (size.value ? `${size.value / 16}rem` : 'auto'));
const computedIconSize = computed(() => (iconSize.value ? `${iconSize.value / 16}rem` : '100%'));
</script>

<style lang="scss" scoped>
.base-icon {
  width: v-bind(computedSize);
  height: v-bind(computedSize);
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  &.v-chip {
    padding: 0;
    cursor: inherit;
  }
  :deep() {
    .v-btn__content,
    .v-chip__content {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__icon {
    width: 50%;
    height: 50%;
    // &--fill {
    //   :deep(*) {
    //     fill: currentColor;
    //   }
    // }
    // &--stroke {
    //   :deep(*) {
    //     stroke: currentColor;
    //   }
    // }
  }
  &--rounded {
    border-radius: 100%;
  }
  &--full & {
    &__icon {
      width: 100%;
      height: 100%;
    }
  }
  &--icon-size & {
    &__icon {
      width: 100%;
      max-width: v-bind(computedIconSize);
      height: auto;
    }
  }
}
</style>
