import { ref } from 'vue';

import { encode, decode } from 'gpt-tokenizer';
import * as Sentry from '@sentry/browser';

export function useTokenizer() {
  const currentTokenAmount = ref(0);
  const isTokenLimitExceeded = ref(false);
  const currentTokenPercentage = ref(0);

  const getTokens = (text) => {
    let encodedText = [];
    try {
      encodedText = encode(text);
    } catch (error) {
      Sentry.captureException('Error when encoding tokens : ', error);
    }
    return encodedText;
  };

  const countTokens = (text, limit) => {
    const tokensArray = getTokens(text);
    currentTokenAmount.value = tokensArray.length;
    isTokenLimitExceeded.value = tokensArray.length > limit;
    currentTokenPercentage.value = Math.floor((tokensArray.length / limit) * 100);
  };

  const decodeTokens = (tokensArray) => {
    let decodedText = '';
    try {
      decodedText = decode(tokensArray);
    } catch (error) {
      Sentry.captureException('Error when decoding tokens : ', error);
    }
    return decodedText;
  };

  return {
    getTokens,
    decodeTokens,
    currentTokenAmount,
    isTokenLimitExceeded,
    countTokens,
    currentTokenPercentage,
  };
}
