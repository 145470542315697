<template>
  <v-select
    class="base-select text-secondary"
    bg-color="bg"
    variant="solo"
    flat
    density="comfortable"
    menu-icon="mdi-chevron-down"
    elevation="0"
    single-line
    hide-details="auto">
    <template #item="{ props }">
      <v-list-item class="base-select__item" v-bind="props" />
    </template>
    <template v-if="hasSlot('selection')" #selection="selectionProps">
      <slot name="selection" v-bind="selectionProps" />
    </template>
  </v-select>
</template>

<script setup>
import { useSlots } from 'vue';

const slots = useSlots();
const hasSlot = (name = 'default') => !!slots[name];
</script>

<style lang="scss" scoped>
.base-select {
  min-width: rem(80px);

  &.v-input--horizontal {
    grid-template-areas: unset !important;
    display: flex;
  }

  &.v-select--active-menu {
    :deep() {
      .v-field {
        &__clearable {
          display: flex;
        }
      }
    }
  }
  :deep() {
    .v-input__details {
      display: none;
    }
    .v-input__control {
      flex-grow: 1;
    }

    .v-select {
      &__menu-icon {
        margin: 0;
      }
      &__selection,
      &__selection > * {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
    .v-list {
      box-shadow: unset;
    }
    .v-field {
      padding-right: rem(4px);
      display: flex;
      .v-field__outline {
        --v-field-border-opacity: 0.7;
      }
      .v-field__append-inner {
        color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
      }
      &:hover {
        .v-field__outline {
          --v-field-border-opacity: 1;
        }
      }
      &__input {
        padding-left: rem(12px);
        padding-right: 0;
        padding-block: 0;
        input {
          flex-grow: 1;
          align-self: unset;
        }
      }
      &__clearable {
        display: none;
        margin: 0;
      }
      &:focus-within {
        outline-width: 2px;
      }
    }
    .v-field__field {
      height: 100%;
    }
    .v-field__input {
      color: rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity));
      min-height: unset;
      height: 100%;
      padding-left: 0px !important;
    }
  }
  &__item {
    :deep() {
      min-height: unset;
      .v-list-item-title {
        font-size: rem(14px);
      }
      &.v-list-item {
        &--active {
          .v-list-item-title {
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
