<template>
  <v-snackbar
    v-if="showSnackbar"
    class="policy-popup"
    :content-class="[
      'policy-popup__container',
      {
        'policy-popup__container--mobile': mobile,
      },
    ]"
    v-model="model"
    :timeout="-1"
    elevation="0"
    variant="text"
    density="compact"
    location="bottom"
    offset="60"
    position="fixed">
    <div
      class="policy-popup__content text-text bg-bg"
      :class="{
        'policy-popup__content--mobile': mobile,
      }">
      <div class="policy-popup__label">
        {{ t('global.byUsingAgree') }}
        <a
          class="link link--underlined"
          href="/privacy"
          target="_blank"
          @click="trackPolicyDocumentClicked('privacy')"
          >{{ t('footer.privacy') }}</a
        >
        {{ t('global.and') }}
        <a
          class="link link--underlined"
          href="/terms"
          target="_blank"
          @click="trackPolicyDocumentClicked('terms')"
          >{{ t('footer.terms') }}</a
        >
      </div>
      <BaseButton class="popup__btn bg-bg" color="text" variant="outlined" @click="model = false">
        OK
      </BaseButton>
    </div>
  </v-snackbar>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import BaseButton from './BaseButton.vue';
import { getRouteName } from '@/utils';


const store = useStore();
const { t } = useI18n();
const route = useRoute();
const { mobile } = useDisplay();

const model = ref(true);

const showSnackbar = computed(() => !store.state.isUserLoading && !store.getters.isLoggedIn);

const trackPolicyDocumentClicked = (type) => {
  const routeName = getRouteName(route, true);
  store.dispatch('trackEvent', {
    event: 'PolicyDocumentClicked',
    values: {
      page: routeName,
      content: type,
      location: 'cookie_banner',
    },
  });
};
</script>

<style lang="scss">
.policy-popup {
  &__container {
    width: 95%;
    min-height: unset !important;
    bottom: rem(54px) !important;
    box-shadow: 0px 4px 12px 0px #0000000f;
    border-radius: rem(200px);
    .v-snackbar__content {
      padding: 0;
    }
    &--mobile {
      bottom: rem(16px) !important;
      min-width: unset;
      border-radius: rem(40px);
    }
  }
}
</style>

<style lang="scss" scoped>
.policy-popup {
  &__content {
    padding: rem(12px) rem(24px);
    border: $border;
    border-radius: rem(200px);
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: rem(32px);
    align-items: center;
    &--mobile {
      border-radius: rem(40px);
    }
  }
}
</style>
