<template>
  <v-btn
    class="expand-button"
    flat
    :icon="right ? 'mdi-chevron-right' : 'mdi-chevron-left'"
    :color="color"
    :active="false" />
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  right: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: 'primary',
  },
});

const { right } = toRefs(props);
</script>

<style lang="scss" scoped>
.expand-button {
  text-decoration: none;
}
</style>
